<template lang="pug">
v-tooltip(top v-if="!!banState")
  p 因为 {{ banState.reason }}
  p 在 {{ formatTime(banState.startedAt) }}
  p 被 {{ banState.dealer.name }}
  p {{ formatBanTime(banState.expireAt) }}
  template(#activator="{ on }")
    span(v-on="on")
      router-link(:to="{ name: 'profile', params: { uid: banState.dealer.uid } }") {{ banState.dealer.name }}
      |  - {{ formatBanTime(banState.expireAt) }}
span(v-else) 正常
</template>

<script>
  import { formatTime, formatBanTime } from '../../utils/format'
  export default {
    props: {
      banState: {
        type: Object,
        default: null,
      },
    },
    methods: {
      formatTime,
      formatBanTime,
    },
  }
</script>
