import * as constants from './error.const'
import * as actions from './error.action'
import * as getters from './error.getter'

const state = {}

const mutations = {
  [constants.CLEAN] (state) {
    for (const i in state) {
      if (Object.prototype.hasOwnProperty.call(state, i)) {
        state[i] = undefined
      }
    }
  },
}

for (const i in constants) {
  if (Object.prototype.hasOwnProperty.call(constants, i) && /^throw/.test(constants[i])) {
    const type = i.toLowerCase()
    state[type] = undefined
    mutations[constants[i]] = function (state, error) {
      state[type] = error
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
