export function authStatus ({ fetching, _id }) {
  return fetching ? 'fetching' : _id ? 'signed' : 'unsigned'
}

export function me ({ uid, avatar, name }) {
  return { uid, avatar, name }
}

export function isAdmin ({ fetching, _id, role, banState }) {
  return !fetching && _id && (role === 'admin' || role === 'su') && !banState
}

const roles = ['user', 'admin', 'su']

export function hasAuthorityAgainst ({ role, banState }) {
  return (targetRole) => {
    if (role !== 'admin' && role !== 'su') {
      return false
    }

    if (banState) {
      return false
    }

    return roles.indexOf(role) > roles.indexOf(targetRole)
  }
}
