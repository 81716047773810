<template lang="pug">
v-app
  navbar
  custom-error
  VMain
    router-view
  vue-progress-bar
</template>

<script>
  import navbar from './components/TheNavbar.vue'
  import customError from './components/CustomError.vue'

  export default {
    components: {
      navbar,
      customError,
    },
  }
</script>

<style lang="sass">
$font-stack: Helvetica, sans-serif
$primary-color: #999

body
  font: 100%, $font-stack
  color: $primary-color

a.inherit
  color: 0x000000
  text-decoration: none
</style>
