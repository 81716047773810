import Vue from 'vue'
import { config as requestCfg } from 'super-res2'
import VueProgressBar from 'vue-progressbar'

import appOptions from './App.vue'
import vuetify from './vuetify'
import router from './router'
import store from './vuex/store'
import './config/veeValidate'
import './config/i18n'
import { doAfterRoute, broadcast } from './utils/doAfterRoute'

Vue.use(VueProgressBar)

/*
 * Add request hooks before all rendering.
 * 调试时每次HMR之后必须手动刷新页面，否则这会重复加载！
 */
requestCfg.transformRequest.push(function (data) {
  if (localStorage.getItem('token')) {
    this.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
  }
  return data
})

requestCfg.catchResponseError.push(function (err) {
  store.dispatch('error/handleErrors', err)

  // 当返回401若仍处于需在线页面，立即退出
  if (err.response && err.response.status === 401 && router.currentRoute.path !== '/signin') {
    store.dispatch('auth/logout')
  }

  throw err
})

router.beforeEach(async (to, from, next) => {
  store.commit('error/clean')

  // 要去的需在线页面，检查会话状态
  let authStatus = store.getters['auth/authStatus']
  if (authStatus === 'fetching') {
    authStatus = await new Promise((resolve, reject) => {
      const unwatch = store.watch((state, getters) => getters['auth/authStatus'], (newVal) => {
        if (newVal !== 'fetching') {
          unwatch()
          resolve(newVal)
        }
      })
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (authStatus === 'unsigned') {
      doAfterRoute(() => {
        store.commit('error/throwCustom', { message: '请先登入', type: 'warning' })
      })
      next({
        path: '/signin',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  // 激活所有doAfterRoute钩子
  broadcast()
})

// auth
if (localStorage.getItem('token')) {
  store.dispatch('auth/sign', localStorage.getItem('token'))
}
// refresh when logout
store.subscribeAction({
  after (action) {
    if (action.type === 'auth/logout') {
      if (router.currentRoute.matched.some(record => record.meta.requiresAuth)) {
        router.go(0)
      } else if (router.currentRoute.path !== '/signin') {
        store.commit('error/throwCustom', { message: '登入状态已失效，请重新登入', type: 'warning' })
      }
    }
  },
})

// Mount
const App = Vue.extend(appOptions)

export default new App({
  el: '#app',
  router,
  store,
  vuetify,
  created () {
    this.$Progress.start()
  },
  mounted () {
    this.$Progress.finish()

    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },
})
