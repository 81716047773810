/**
 * 获取vee-validate可识别的错误
 * 需要当前状态里有mongoose格式的表单错误，否则返回空对象。
 * @param \{ state \}
 * @returns 可供vee-validate识别的错误
 */
export function veeErrors ({ validation }) {
  const errors = {}
  if (validation && typeof validation === 'object') {
    for (const field in validation) {
      if (Object.prototype.hasOwnProperty.call(validation, field)) {
        errors[field] = [validation[field].properties.message]
      }
    }
  }
  return errors
}
