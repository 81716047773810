import { generateFromString } from 'generate-avatar'
import { getRepoAddr } from '../config/address'

/**
 * 由字符串生成合法的SVG DataURI
 */
export const stringToSVG = (str: String) => encodeURI(`data:image/svg+xml;utf8,${generateFromString(str)}`) // 转义替换掉一些符号，使URI合法

/**
 * 将user对象的avatar改为链接
 * 改原
 * 若无头像文件，则从昵称生成头像，所以需要name
 */
export const generateAvatar = (user: {
  name: ?String,
  avatar: ?String
}) => {
  if (user.name) {
    if (user.avatar) {
      user.avatar = getRepoAddr(user.avatar)
    } else {
      user.avatar = stringToSVG(user.name)
    }
  }
  return user
}
