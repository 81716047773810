/**
 * 默认格式
 * { message: 'message', name: 'ErrorName' }
 */
export const CUSTOM = 'throwCustom'

export const AUTH = 'throwAuth'
export const NOT_FOUND = 'throwNotFound'
export const GONE = 'throwGone'

/**
 * 格式将是mongoose的，如：
 * {
 *   email: {
 *     name: 'ValidatorError',
 *     properties: {
 *       message: 'message',
 *       ...
 *     },
 *     kind: '原因',
 *     path: [path],
 *     value: [原值]
 *   }
 * }
 */
export const VALIDATION = 'throwValidation'

export const CLEAN = 'clean'
