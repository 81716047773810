<template lang="pug">
v-card(:loading="dealPending")
  v-container(fluid)
    NotifyOverlay(ref="notifier")
    form(@submit.prevent="submit")
      v-radio-group(v-model="action")
        template(#label="") 处理用户
        v-radio(value="banIn")
          template(#label="")
            v-select(
              v-model="banIn"
              :items="banInAlternatives"
              label="按时长封禁"
              prepend-icon="event"
              dense
              outlined
            )
        v-radio(value="banUntil")
          template(#label="")
            v-menu(
              v-model="displayBanUntilMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            )
              template(#activator="{ on }")
                v-text-field(
                  v-model="banUntil"
                  v-on="on"
                  readonly
                  label="按截止日期封禁"
                  prepend-icon="event"
                  outlined
                )
              v-date-picker(
                v-model="banUntil"
                @input="displayBanUntilMenu = false"
                locale="zh-cn"
                :show-current="true"
              )
        v-radio(value="banPermanent" label="永久封禁")
        v-radio(value="unban" label="解除封禁")
        v-radio(value="delete" label="删除用户")
      v-text-field(v-model="reason" label="理由" outlined counter maxlength="50")
      v-card-actions
        v-btn#admin-user-reset(@click="reset") 清空
        v-btn#admin-user-submit(color="primary" type="submit" :disabled="dealPending") 处理
</template>

<script>
  import moment from 'moment'
  import NotifyOverlay from '../../components/overlays/NotifyOverlay'
  import user from '../../models/user'

  const commonForm = (ctx) => ({
    users: ctx.users,
    reason: ctx.reason,
  })
  const actions = {
    banIn (ctx) {
      return user.ban({
        ...commonForm(ctx),
        expireAt: moment().add(...ctx.banIn.value).valueOf(),
      })
    },
    banUntil (ctx) {
      return user.ban({
        ...commonForm(ctx),
        expireAt: moment(ctx.banUntil).valueOf(),
      })
    },
    banPermanent (ctx) {
      return user.ban({
        ...commonForm(ctx),
        expireAt: null,
      })
    },
    unban (ctx) {
      return user.ban(commonForm(ctx))
    },
    delete (ctx) {
      return user.del({}, commonForm(ctx))
        .then((result) => {
          ctx.$emit('deleted')
          return result
        })
    },
  }

  export default {
    components: {
      NotifyOverlay,
    },
    /**
     * props
     * @param {[String]} users _id列表
     */
    props: {
      users: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        dealPending: false,
        banInAlternatives: [
          { text: '3天', value: [3, 'days'] },
          { text: '1周', value: [1, 'week'] },
          { text: '1个月', value: [1, 'month'] },
          { text: '3个月', value: [3, 'months'] },
          { text: '6个月', value: [6, 'months'] },
          { text: '1年', value: [1, 'year'] },
        ],
        displayBanUntilMenu: false,
        banIn: { text: '1个月', value: [1, 'month'] },
        banUntil: moment().format('YYYY-MM-DD'),
        action: 'banIn',
        reason: '',
      }
    },
    methods: {
      reset () {
        this.action = 'banIn'
        this.reason = ''
      },
      async submit () {
        this.dealPending = true
        try {
          const resBody = await actions[this.action](this)
          this.$refs.notifier.activate()
          this.$emit('success', resBody)
        } catch (e) {
          this.$emit('fail', e)
        } finally {
          this.dealPending = false
        }
      },
    },
  }
</script>
