<template lang="pug">
v-btn(v-bind="$attrs" :disabled="disabled || counts > 0" v-on="$listeners")
  span(v-if="counts > 0") {{ counts }}
  slot(v-else)
</template>

<script>
  // 倒计时按钮，倒计时结束才能点击
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        intervalId: 0,
        counts: 0,
      }
    },
    beforeDestroy () {
      if (this.intervalId) clearInterval(this.intervalId)
    },
    methods: {
      countDown (seconds = 60) {
        if (this.intervalId) clearInterval(this.intervalId)
        this.counts = seconds
        this.intervalId = setInterval(() => {
          if (this.counts > 0) {
            this.counts -= 1
          } else {
            this.counts = 0
            this.intervalId = 0
            clearInterval(this.intervalId)
          }
        }, 1000)
      },
    },
  }

</script>
