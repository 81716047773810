import { request } from 'super-res2'
import user from '../../models/user'
import { authServer } from '../../config/address'

import { SIGN, REFRESH, CLEAN } from './auth.const'
const authServerLocal = authServer + '/local'

/**
 * 从服务器获取登录信息
 * @param { state, dispatch, commit }
 * @param token 本地存储中的token，不填则默认用state里的（用于修改资料后的刷新，token没变）
 * @returns {Promise|Promise.<T>}
 */
export async function sign ({ state, dispatch, commit }, token) {
  commit(SIGN, token)
  try {
    state.fetching = true
    const curUser = await user.get()
    commit(REFRESH, curUser)
    state.fetching = false
  } catch (err) {
    state.fetching = false
    dispatch('logout')
    throw err
  }
}

/**
 * 注册
 * @param { dispatch }
 * @param form 所需的用户信息
 * @returns {*|Promise}
 */
export async function register ({ dispatch }, form) {
  const body = await user.post(form)
  dispatch('sign', body.token)
}

/**
 * 登入
 * @param { dispatch }
 * @param user 用户信息：account和password
 * @returns {*|Promise}
 */
export async function login ({ dispatch }, form) {
  const res = await request.post(authServerLocal).send(form)
  dispatch('sign', res.body.token)
}

/**
 * 登出
 * 当需要一个完整的登出行为时调用这个方法，将会弹出消息
 * 如果不需要，应调用commit('auth/clean')
 * @param { commit }
 */
export function logout ({ commit }) {
  commit(CLEAN)
}
