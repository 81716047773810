<template lang="pug">
div: v-toolbar
  v-app-bar-nav-icon
  v-toolbar-title
    router-link.inherit(to="/") 跑团客（删档测试）
  v-toolbar-items
    v-btn(text v-if="isAdmin" to="/admin/user") 管理
  v-spacer
  v-toolbar-items(v-if="authStatus==='unsigned'")
    v-btn#nav-sin(text to="/signin") 登入
    v-btn#nav-sup(text to="/signup") 注册
  v-toolbar-title#nav-signing(v-else-if="authStatus==='fetching'") 登入中...
  v-toolbar-items(v-else-if="authStatus==='signed'")
    v-btn#nav-name(text :to="`/profile/${me.uid}`")
      v-avatar(tile)
        img#nav-avatar(:src="me.avatar" aspect-ratio="1")
      span(v-text="me.name")
    v-btn#nav-settings(text to="/settings") 设置
    v-btn#nav-signout(text @click="logout") 退出
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters('auth', ['authStatus', 'me', 'isAdmin']),
    },
    methods: {
      ...mapActions('auth', ['logout']),
    },
  }

</script>

<style lang="sass" scoped>
span
  margin-left : 1rem
</style>
