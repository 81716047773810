import _ from 'lodash'
import moment from 'moment'

/**
 * 将时间转换成可读的格式
 * @param {*} time Any ISO time format.
 * @returns {String}
 */
export function formatTime (time) {
  return moment(time).format('lll')
}

/**
 * 将解封时间转换成可读的格式
 * @param {*} time Any ISO time format.
 * @returns {String}
 */
export function formatBanTime (expireAt) {
  return expireAt ? `封禁至${formatTime(expireAt)}` : '永久封禁'
}

/**
 * 将vuetify表格的查询条件格式（页数、每页显示数、排序）转换成Mongoose query options
 * @param {Number} page 第几页，1开头
 * @param {Number} itemsPerPage 每页显示数，默认10
 * @param {Object|String} sortOptions 排序，如果是字符串则默认已经是mongoose格式，如果是对象则默认是vuetify表格排序选项，而非mongoose格式的排序选项
 * @returns {String}
 */
export function formatQueryOptions (page = 1, itemsPerPage = 10, sortOptions = '') {
  let sort = ''
  if (_.isPlainObject(sortOptions)) {
    sort = _.chain(sortOptions.sortBy)
    .map((value, i) => sortOptions.sortDesc[i] ? '-' + value : value)
    .join(' ')
    .value()
  } else if (typeof sortOptions === 'string') {
    sort = sortOptions
  }

  return {
    sort,
    skip: (page - 1) * itemsPerPage,
    limit: itemsPerPage,
  }
}
