import { extend, localize } from 'vee-validate'
import hans from 'vee-validate/dist/locale/zh_CN'
import * as rules from 'vee-validate/dist/rules'

for (const rule in rules) {
  extend(rule, rules[rule])
}

localize({
  zh_CN: {
    ...hans,
    names: {
      account: '账号',
      email: '邮箱',
      password: '密码',
      pwdagn: '密码确认',
      oldPassword: '旧密码',
      newPassword: '新密码',
      name: '昵称',
    },
    fields: {
      pwdagn: {
        confirmed: '必须输入相同的密码',
      },
    },
  },
})

localize('zh_CN')
