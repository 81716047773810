/**
 * Created by quentine on 16-7-12.
 *
 * 错误应该遵循一定格式
 *
 * 可预知的错误应该直接不让它发生，如本地错误。
 * 剩下的就是服务器发回来的，应该按照状态码归类。调用者本身默认了一种类型，被调用者简单检查一下特征，
 * 不符合者打回默认处理流程。
 *
 * 如：表单验证错误，status是422，调用者明确知道返回的错误应该投向validation errors流程，
 * 则默认调用对应函数，而不是通用处理函数。
 *
 * 所有status都在这处理吗？ 不，这应该是最后的钩子，但所有需要显示的错误在这里处理。
 */

import { CUSTOM, AUTH, NOT_FOUND, VALIDATION, GONE } from './error.const'

/**
 * handleErrors
 * 最终的通用错误处理流程，应作为最后一个响应钩子。
 * 但是错误还是要继续抛给非通用捕获
 * @param \{ commit \}
 * @param error 原封不动的superAgent响应错误
 */
export function handleErrors ({ commit }, error: Error) {
  let errMutation = CUSTOM; let errContent
  if (error.original) {
    errContent = error.original
  } else if (error.response) {
    switch (error.response.status) {
      case 401:
        errMutation = AUTH
        errContent = error.response.body
        break
      case 404:
        errMutation = NOT_FOUND
        errContent = error.response.body
        break
      case 410:
        errMutation = GONE
        errContent = error.response.body
        break
      case 422:
        errMutation = VALIDATION
        errContent = error.response.body.errors
        break
      default:
        errContent = error.response.body
    }
  } else {
    errContent = error
  }
  if (typeof errContent === 'string') {
    errContent = new Error(errContent)
  }
  commit(errMutation, errContent)
}
