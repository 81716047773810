const EVENT_AFTER_ROUTE = 'afterRoute'

const afterRoute = new EventTarget()

export function doAfterRoute (fn: Function) {
  afterRoute.addEventListener(EVENT_AFTER_ROUTE, listener)
  function listener () {
    afterRoute.removeEventListener(EVENT_AFTER_ROUTE, listener)
    fn()
  }

  return function cancel () {
    afterRoute.removeEventListener(EVENT_AFTER_ROUTE, listener)
  }
}

export function broadcast () {
  afterRoute.dispatchEvent(new Event(EVENT_AFTER_ROUTE))
}
