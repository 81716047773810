<template lang="pug">
.click-overlay(@click.capture.stop="dismissable && deactivate()")
  v-overlay(
    absolute
    v-model="activated"
    :color="type"
    opacity="1"
  )
    v-container.text-center
      CountDownCircle(:size="54" :width="3" :timeout="expireTime" :positive="false")
        v-icon(x-large) {{ icon }}
      h2(v-if="message") {{ message }}
</template>

<script>
  import CountDownCircle from './CountDownCircle.vue'

  // 覆盖显示式的提醒
  const icons = {
    primary: 'done',
    secondary: 'done',
    accent: 'done',
    error: 'error',
    info: 'error',
    success: 'done',
    warning: 'warning',
  }

  const EVENT_RESOLVE_PROMISE = 'resolvePromise'

  export default {
    components: {
      CountDownCircle,
    },
    props: {
      dismissable: {
        type: Boolean,
        default: true,
      },
      expireTime: {
        type: Number,
        default: 500, // 单位毫秒
      },
      type: {
        type: String,
        default: 'success',
        validator (value) {
          return Object.keys(icons).indexOf(value) !== -1
        },
      },
      message: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        timeoutID: 0,
        eventTarget: new EventTarget(),
        activated: false,
      }
    },
    computed: {
      icon () {
        return icons[this.type]
      },
    },
    beforeDestroy () {
      this.clear()
    },
    methods: {
      activate () {
        this.activated = true
        clearTimeout(this.timeoutID)
        return new Promise((resolve) => {
          const resolvePromise = () => {
            this.eventTarget.removeEventListener(EVENT_RESOLVE_PROMISE, resolvePromise)
            resolve()
          }

          this.eventTarget.addEventListener(EVENT_RESOLVE_PROMISE, resolvePromise)

          if (this.expireTime > 0) {
            this.timeoutID = setTimeout(() => {
              this.deactivate()
            }, this.expireTime)
          } else if (!this.dismissable) {
            // 既不可手动关闭，也没有自动关闭倒计时时，直接resolve，保证所有promise都可完结
            this.eventTarget.dispatchEvent(new Event(EVENT_RESOLVE_PROMISE))
          }
        })
      },
      deactivate () {
        this.activated = false
        this.eventTarget.dispatchEvent(new Event(EVENT_RESOLVE_PROMISE))
      },
      clear () {
        this.deactivate()
        clearTimeout(this.timeoutID)
      },
    },
  }
</script>

<style lang="scss" scoped>
.click-overlay {
  width: 100%;
  height: 100%;
}
</style>
