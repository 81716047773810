import superRes from 'super-res2'
import { apiServer } from '../config/address'
import { compressObject } from '../utils'
import { generateAvatar } from '../utils/generateAvatar'

const user = superRes.resource(`${apiServer}/user/:uid/:controller`, {}, {
  get: {
    method: 'GET',
    transformResponse: [generateAvatar],
  },

  // 设置
  changePassword: {
    method: 'PATCH',
    params: {
      controller: 'password',
    },
  },
  setProfile: {
    method: 'PATCH',
    params: {
      controller: 'profile',
    },
  },
  setAvatar: {
    method: 'PATCH',
    params: {
      controller: 'avatar',
    },
  },
  // 获取可能正在审核的头像，若无则返回空
  getApprovalPendingAvatar: {
    method: 'GET',
    params: {
      controller: 'avatar',
    },
  },

  adminShow: {
    method: 'GET',
    params: {
      controller: 'adminshow',
    },
    transformResponse: [generateAvatar],
  },
  adminQuery: {
    method: 'GET',
    params: {
      controller: 'query',
    },
    paramPreprocessors: {
      query: compressObject,
      options: compressObject,
    },
    transformResponse: [
      function (body: {
        results: Array,
        count: Number
      }) {
        body.results.map(generateAvatar)
        return body
      },
    ],
  },
  ban: {
    method: 'PATCH',
    params: {
      controller: 'banish',
    },
  },
  /**
   * 删除请求
   * 注意DELETE方法不会自动省略参数，因为RFC并不严格规定DELETE必须还是不能携带请求体。
   * 所以使用请求体时，请自行传入空参数。
   * 如 user.del({}, { users: [...], reason: '...' })
   */
  del: {
    method: 'DELETE',
  },
  setRole: {
    method: 'PATCH',
    params: {
      controller: 'role',
    },
  },

  // 注册后的邮箱验证
  resendVerificationEmail: {
    method: 'GET',
    params: {
      controller: 'cremail',
    },
  },
  confirmEmail: {
    method: 'GET',
    params: {
      controller: 'crconfirm',
    },
  },

  // 找回密码三步
  verifyEmail: {
    method: 'POST',
    params: {
      controller: 'fpemail',
    },
  },
  preVerifyToken: {
    method: 'GET',
    params: {
      controller: 'fptoken',
    },
  },
  setPassword: {
    method: 'POST',
    params: {
      controller: 'fpsetpwd',
    },
  },
})

export default user
