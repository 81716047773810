import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  hashBang: false,
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
  routes: [{
    path: '/',
    component: () => import('./business/Main.vue'),
  }, {
    path: '/signin',
    component: () => import('./business/auth/SignIn.vue'),
  }, {
    path: '/signup',
    component: () => import('./business/auth/SignUp.vue'),
  }, {
    path: '/confirmemail',
    props: (route) => ({ token: route.query.token }),
    component: () => import('./business/auth/EmailVerified.vue'),
  }, {
    path: '/forgotpwd',
    component: () => import('./business/forgotPwd/SendEmail.vue'),
  }, {
    path: '/resetpwd',
    props: (route) => ({ token: route.query.token }),
    component: () => import('./business/forgotPwd/ResetPwd.vue'),
  }, {
    path: '/profile/:uid',
    props: true,
    component: () => import('./business/profile/index.vue'),
    children: [
      {
        name: 'profile',
        path: '',
        component: () => import('./business/profile/Main.vue'),
      },
      {
        name: 'logOfBeManaged',
        path: 'managed',
        component: () => import('./business/profile/ManagementLog.vue'),
        props: ({ params }) => ({ fetchBy: 'dealee', uid: params.uid }),
        meta: { requiresAuth: true },
      },
      {
        name: 'logOfManagement',
        path: 'management',
        component: () => import('./business/profile/ManagementLog.vue'),
        props: ({ params }) => ({ fetchBy: 'dealer', uid: params.uid }),
        meta: { requiresAuth: true },
      },
    ],
  }, {
    path: '/admin/:type',
    component: () => import('./business/admin/index.vue'),
    props: true,
    meta: { requiresAuth: true },
  }, {
    path: '/settings',
    component: () => import('./business/settings/index.vue'),
    children: [
      {
        path: '',
        component: () => import('./business/settings/Main.vue'),
      },
      {
        path: 'password',
        component: () => import('./business/settings/Password.vue'),
      },
      {
        path: 'profile',
        component: () => import('./business/settings/Profile.vue'),
      },
            {
        path: 'avatar',
        component: () => import('./business/settings/Avatar.vue'),
      },
    ],
    meta: { requiresAuth: true },
  }, {
    path: '/404/*',
    component: () => import('./business/404.vue'),
  }, {
    path: '*',
    component: () => import('./business/404.vue'),
  }],
})
