import { SIGN, REFRESH, CLEAN } from './auth.const'
import * as actions from './auth.action'
import * as getters from './auth.getter'
import defAvatar from '../../utils/default.resource'

const defaultState = Object.freeze({
  token: '',
  fetching: false,
  _id: '',
  uid: '',
  email: '',
  name: '',
  role: '',
  avatar: defAvatar,
  banState: null,
  emailUnverified: false,
})

const state = Object.assign({}, defaultState)

const mutations = {
  [SIGN] (state, token) {
    if (token) state.token = token
    localStorage.setItem('token', state.token)
  },
  [REFRESH] (state, data) {
    Object.assign(state, data)
  },
  [CLEAN] (state) {
    localStorage.removeItem('token')
    Object.assign(state, defaultState)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
