<template lang="pug">
v-progress-circular(:value="progress" v-bind="$attrs" v-on="$listeners")
  slot
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      timeout: {
        type: Number,
        default: 1000,
      },
      positive: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        intervalID: 0,
        progress: this.positive ? 0 : 100,
      }
    },
    mounted () {
      if (this.intervalID) {
        clearInterval(this.intervalID)
      }

      const start = Date.now()
      const direction = this.positive ? 1 : -1
      this.progress = this.positive ? 0 : 100

      this.intervalID = setInterval(() => {
        const _progress = (Date.now() - start) / this.timeout * 100

        if (!_.inRange(_progress, 0, 100)) {
          clearInterval(this.intervalID)
        }
        this.progress = direction * (_.clamp(_progress, 0, 100) - 50) + 50
      }, 600)
    },
    beforeDestroy () {
      this.clear()
    },
    methods: {
      clear () {
        if (this.intervalID) {
          clearInterval(this.intervalID)
          this.intervalID = 0
        }
        this.progress = 0
      },
    },
  }
</script>
