/**
 * Created by quentine on 16-7-11.
 */
import Vue from 'vue'
import Vuex from 'vuex'

// ---------------------------------------------------------------
import auth from './auth/auth.module'
import error from './error/error.module'
Vue.use(Vuex)

// ---------------------------------------------------------------
export default new Vuex.Store({
  strict: Vue.config.debug,
  modules: {
    auth,
    error,
  },
})
